<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">

        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedVertical" :items="verticals" item-text="name" item-value="id" label="Vertical" 
              clearable prepend-icon="construction">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-btn class="mt-2" color="primary" @click="show">Show</v-btn>
          </v-col>

          <v-spacer></v-spacer>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Keyword collector blacklist detail', params: { itemID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn icon @click="deleteItem(item.id)">
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'KeywordCollectorBlacklistTable',

  data () {
    return {
      headers: [
        { text: 'Vertical', value: 'vertical' },
        { text: 'Country', value: 'country_iso2' },
        { text: 'Language', value: 'language_iso2' },
        { text: 'Keyword', value: 'keyword' },
        { text: 'Entry by', value: 'entry_by' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      selectedVertical: null,
      lsOptions: 'keywordCollectorBlacklist_options',
      lsFilters: 'keywordCollectorBlacklist_filters',
      dataEndpoint: '/a/core/keyword-ideas-blacklist',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    verticals () {
      return this.$store.state.core.verticals
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    deleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }

      var myURL = this.dataEndpoint + '/' + id

      this.$http.delete(myURL).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    fetchData: function () {
      this.loading = true
      var myURL = this.dataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter vertical if set
      if (this.selectedVertical) {
        myURL += '&verticals_fk=' + this.selectedVertical
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
      let filterObj = {
        'selectedVertical': this.selectedVertical,
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedVertical']) { this.selectedVertical = filters['selectedVertical'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
